import React, { useState, useEffect} from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Style/VideoDataComponent.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function VideoDataComponent(props) {
    // state hooks to keep track of all the YouTube statistics
    const [viewCount, setViewCount] = useState();
    const [likeCount, setLikeCount] = useState();
    const [videoTitle, setVideoTitle] = useState();
    const [videoDescription, setVideoDescription] = useState();

    // used to create the alternating pattern for the videos
    const pattern = (props.value % 2 == 0 ? "evenStyle" : "oddStyle");

    // useEffect hook to complete the code specified when the component loads.
    // two different api endpoints needed for different types of statistics
    useEffect(() => {
        // const api_key = process.env.REACT_APP_api_key;
        // const apiCall = `https://www.googleapis.com/youtube/v3/videos?part=statistics&id=${props.videoId}&key=${api_key}`
        fetchStats(setLikeCount, setViewCount, props.videoId);
    });

    useEffect(() => {
        // const api_key = process.env.REACT_APP_api_key;
        // const apiCall = `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${props.videoId}&key=${api_key}`
        fetchDesc(setVideoTitle, setVideoDescription, props.videoId)
    });
    

    return (
        <div>
            <Accordion>
                <div className={pattern}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Container>
                        <Typography>
                            <Row className="d-flex align-items-center">
                                <Col xs={12} md={2} className="text-center">
                                    <p>
                                        <a href={`https://www.youtube.com/watch?v=${props.videoId}`}>
                                            <img src={`https://img.youtube.com/vi/${props.videoId}/default.jpg`} className="cropped"></img>
                                        </a>
                                    </p>
                                </Col>
                                <Col xs={12} md={3}><p>{videoTitle}</p></Col>
                                <Col xs={12} md={{span: 3, offset: 1}}><p>{viewCount}</p></Col>
                                <Col xs={12} md={3}><p>{likeCount}</p></Col>
                            </Row>  
                        </Typography>
                    </Container>  
                </AccordionSummary>
                </div>
                
                <div className={pattern}>
                <AccordionDetails>
                <Container>
                    <Typography>
                        <p>{videoDescription}</p>
                    </Typography>
                </Container>
                </AccordionDetails>
                </div>
            </Accordion>
        </div>
    )
}

async function fetchStats(setLikeCount, setViewCount, videoId) {
    // const apiCall = `http://localhost:8000/videostats/${props.videoId}`;
    // fetch(apiCall)
    // .then(result => result.json())
    // .then(data => {
    //     const likeCount = data.items[0].statistics.likeCount;
    //     const viewCount = data.items[0].statistics.viewCount;
    //     setLikeCount(likeCount);
    //     setViewCount(viewCount);
    // })

    const response = await fetch(`https://lukeyang.dev/api/videostats/${videoId}`);
    const data = await response.json();
    console.log(data);
    const likeCount = data.items[0].statistics.likeCount;
    const viewCount = data.items[0].statistics.viewCount;
    setLikeCount(likeCount);
    setViewCount(viewCount);
}

async function fetchDesc(setVideoTitle, setVideoDescription, videoId) {
    // const apiCall = `http://localhost:8000/videodesc/${props.videoId}`;
    // fetch(apiCall)
    // .then(result => result.json())
    // .then(data => {
    //     const title = data.items[0].snippet.title;
    //     setVideoTitle(title);
    //     const description = data.items[0].snippet.description;
    //     setVideoDescription(description);
    //     console.log(data);
    // })

    const response = await fetch(`https://lukeyang.dev/api/videodesc/${videoId}`);
    const data = await response.json();
    const title = data.items[0].snippet.title;
    setVideoTitle(title);
    const description = data.items[0].snippet.description;
    setVideoDescription(description);
    console.log(data);
}

export default VideoDataComponent
