import Home from './components/HomeComponent';
import Projects from './components/ProjectsComponent';
import Nav from './components/NavComponent';
// import Suggest from './components/SuggestComponent';

import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";

// <Route path="suggest" element={<Suggest />} />
function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Nav />
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="projects" element={<Projects />} />
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
