import React from 'react';
import '../style/HomeComponent.css';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Typewriter from 'typewriter-effect';
import luke from '../assets/images/lukeHead.jpg';
import Codeyear from '../components/CodeyearComponent';
import Designyear from '../components/DesignyearComponent';

function HomeComponent() {
    return (    
        <div>   
            <Container fluid className="homeBanner d-flex align-items-center justify-content-center">
                <Row>
                    <Col sm={12} className="text-center">
                        <Typewriter
                            options={{
                                strings: ['Luke Yang', 
                                    'Third Year EngSci',  
                                    'Tennis Player',
                                    'Musician'],
                                pauseFor: 4000,
                                autoStart: true,
                                loop: true,
                            }}
                        />
                    </Col>
                </Row>
            </Container>
            <div className="hookRow">
                <Container>
                    <Row>
                        <Col xs={12} md={2} className="d-flex justify-content-center"><img src={luke} alt="Luke Headshot" className="headshot"></img></Col>
                        <Col xs={12} md={{span: 9, offset: 1}} className="d-flex align-items-center">
                            <Row>
                                <Col xs={12} className="headerText">Luke Yang</Col>
                                <Col xs={12}>
                                    Third Year Engineering Science student at the University of Toronto, majoring in Robotics Engineering with interests in
                                    3D Printing, Web Development, and Research.
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="skillRow">
                <Container>
                <Row>
                    <Col xs={2} className="linksRow">
                        <Col xs={12} className="d-flex justify-content-center"><a href="https://www.linkedin.com/in/luke-yang-7a0871203">LinkedIn</a></Col>
                        <Col xs={12} className="d-flex justify-content-center"><a href="https://github.com/lukewarmtemp">GitHub</a></Col>
                        <Col xs={12} className="d-flex justify-content-center"><a href="https://www.youtube.com/channel/UCdtqnqdvz2eXEUUMe5ZM-FA">YouTube</a></Col>
                    </Col>
                    <Col xs={{span: 9, offset: 1}}>
                        <Col xs={12}>
                            <Row className="headerTextU">
                                <Col xs={12}>Technical Skills</Col>
                            </Row>
                            <Row>
                                <Col className="headerText2" xs={12}>PROGRAMMING</Col>
                            </Row>
                            <Row className="codeYear">
                                <Codeyear lang="C#" year="2018" />
                                <Codeyear lang="Python" year="2020" />
                                <Codeyear lang="Matlab" year="2020" />
                                <Codeyear lang="C" year="2021" />
                                <Codeyear lang="ROS" year="2021" />
                                <Codeyear lang="HTML" year="2021" />
                                <Codeyear lang="CSS" year="2021" />
                                <Codeyear lang="JavaScript" year="2021" />
                                <Codeyear lang="Verilog" year="2021" />
                                <Codeyear lang="ARM Assembly" year="2021" />
                            </Row>
                            <Row>
                                <Col className="headerText2" xs={12}>DESIGN</Col>
                                <Designyear skill="3D Printing" year="2018"/>
                                <Designyear skill="Arduino" year="2021"/>
                                <Designyear skill="Soldering" year="2021"/>
                                <Designyear skill="Circuit Building" year="2021"/>
                            </Row>
                        </Col>
                    </Col>
                </Row>
                </Container>
            </div>
        </div>
    )
}

export default HomeComponent
