import React from 'react';
import YouTubeVideos from './YouTube/YouTubeVideosComponent';
import '../style/ProjectsComponent.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function ProjectsComponent() {
    return (
        <div>
            <Container fluid className="projectsBanner d-flex align-items-center justify-content-center">
                <Row>
                    <Col sm={12} className="text-center">
                        <p>Projects</p>
                    </Col>
                </Row>
            </Container>
            <div className="overviewRow">
                <Container>
                    <Row>
                        <Col xs={12} className="d-flex align-items-center">
                            <Row>
                                <Col xs={12} className="headerText">Overview</Col>
                                <Col xs={12}>
                                    A collection of projects I've done in the past, mostly consisting of Arudino Projects.
                                    The process has been documented on my YouTube channel and GitHub where applicable.
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <YouTubeVideos />
        </div>
    )
}

export default ProjectsComponent
