import React, { useState, useEffect} from 'react';
import config from './config';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import VideoData from './VideoDataComponent';
import './Style/YoutubeVideosComponent.css';

function YouTubeVideosComponent(props) { 
    // state hooks to keep track of the total number of videos and the ids of each video.
    // this information is fed into the VideoDataComponent which allows the use of the map
    // function
    const [videoCount, setVideoCount] = useState();
    const [videoIds, setVideoIds] = useState([]);

    useEffect(() => {
        // const { channel_id } = config;
        // const api_key = process.env.REACT_APP_api_key;
        // const apiCall = `https://www.googleapis.com/youtube/v3/channels?part=statistics&id=${channel_id}&key=${api_key}`;
	    // fetch("http://localhost:8000")
        // .then(result => result.json())
        // .then(data => {
        //     console.log(data);
        // })
        fetchVideoCount(setVideoCount);
        //fetch("http://localhost:8000/videocount")
        //.then(result => result.json())
        //.then(data => {
        //    const videoCount = data.items[0].statistics.videoCount;
        //    setVideoCount(videoCount);
        //})
    });

    // only make another call to the api when the video count changes. this is important to prevent
    // the api from calling and reacing the request limit of the YouTube API
    useEffect(() => {
        // const { channel_id } = config;
        // const api_key = process.env.REACT_APP_api_key;
        // const apiCallLatestVideos = `https://www.googleapis.com/youtube/v3/search?key=${api_key}&channelId=${channel_id}&part=snippet,id&order=date&maxResults=20`
	/*console.log("hello");
	console.log(videoCount);
        fetch("http://localhost:8000/videodata")
        .then(result => {
	    console.log(result);
	    console.log("hello2");
	    console.log(result.json);
	    result.json();
	})
        .then(data => {
	    console.log("hello3");
	    console.log(data);
            var temp = []
            for (var i = 0; i < videoCount; i++) {
                var newObj = {videoId: data.items[i].id.videoId, index: i}
                temp.push(newObj);
            }
            setVideoIds([]);
            setVideoIds(temp);
        })*/
        // if (videoCount !== 'undefined'){
	    fetchVideoData(setVideoIds, videoCount);
	// }
    }, [videoCount]);

    return (
        <div>
            <div className="videoTableHeadings">
                <Container>
                    <Row className="d-flex align-items-center tableHeadings">
                        <Col xs={12} md={2} className="text-center"></Col>
                        <Col xs={12} md={4}><p>Title</p></Col>
                        <Col xs={12} md={3}><p>Views</p></Col>
                        <Col xs={12} md={3}><p>Likes</p></Col>
                    </Row>
                </Container>
            </div>
                {videoIds.map((video) =>
                    <VideoData key={video.index} videoId={video.videoId} value={video.index}/>
                )}
        </div>
    )
}

async function fetchVideoCount(setVideoCount) {
    const response = await fetch("https://lukeyang.dev/api/videocount");
    const data = await response.json();
    console.log(data);
    const count = data.items[0].statistics.videoCount;
    setVideoCount(count);
}

async function fetchVideoData(setVideoIds, videoCount) {
const response = await fetch("https://lukeyang.dev/api/videodata");
    const data = await response.json();
    var temp = []
    for (var i = 0; i < videoCount; i++) {
       var newObj = {videoId: data.items[i].id.videoId, index: i}
       temp.push(newObj);
    }
    setVideoIds([]);
    setVideoIds(temp);
}

export default YouTubeVideosComponent;
