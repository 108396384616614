import React from 'react'
import { Container, Navbar, Nav} from 'react-bootstrap';

// <Nav.Link href="suggest">Suggestions</Nav.Link>

function NavComponent() {
    return (
        <div>
            <Navbar bg="dark" variant="dark">
                <Container>
                <Navbar.Brand href="home">Luke Yang</Navbar.Brand>
                <Nav className="me-auto">
                    <Nav.Link href="home">Home</Nav.Link>
                    <Nav.Link href="projects">Projects</Nav.Link>
                </Nav>
                </Container>
            </Navbar>
        </div>
    )
}

export default NavComponent
