import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import ProgressBar from 'react-bootstrap/ProgressBar'

function DesignyearComponent(props) { 
    // simple way to calcuate the correct progress bar percentage based on how many
    // years of experience I have with the design skill
    const curDate = new Date().getFullYear();
    const baseDate = 2018;
    const designDate = ((curDate - props.year) / (curDate - baseDate)) * 100;

    return (
        <div>
            <Row>
                <Col xs={12} md={2}><p>{props.skill}</p></Col>
                <Col xs={12} md={1}>{props.year}</Col>
                <Col xs={12} md={9}>
                    <ProgressBar variant="warning" now={designDate} label={`${designDate}%`} visuallyHidden />
                </Col>
            </Row>
        </div>
    )
}

export default DesignyearComponent
